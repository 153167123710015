import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getSummary } from '../../modules/employees/employeesSlice';
import Duration from '../utils/Duration';
import { importWorktimeSummary } from '../../modules/worktimeSummary/utils';

const WorkTimeSummary = ({ fetchParams }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [summary, setSummary] = useState(null);

    useEffect(() => {
        if (!summary) {
            dispatch(getSummary(fetchParams)).then(result => {
                if (result?.data) {
                    setSummary(importWorktimeSummary(result.data));
                }
            });
        }
    }, [fetchParams, summary]);

    return (
        <Grid
            container
            spacing={1}
            wrap="nowrap"
            sx={{
                marginLeft: 0,
                width: '100%',
                display: 'inline-flex',
            }}
        >
            {summary &&
                Object.entries(summary).map(([key, value]) => (
                    <Grid key={key} item xs>
                        <Typography variant="h5" color="inherit">
                            {t(`components.JournalSummary.${key}`)}
                        </Typography>
                        <Duration durationValue={value} asDecimal />
                    </Grid>
                ))}
        </Grid>
    );
};

WorkTimeSummary.propTypes = {
    fetchParams: PropTypes.shape({}),
};

WorkTimeSummary.defaultProps = {
    fetchParams: {},
};

export default WorkTimeSummary;
