import CardModal from '../card/CardModal';
import ContactDialog from '../contacts/dialog/ContactDialog';
import ResourceDialog from '../resourceDialog/ResourceDialog';
import {
    cardPath,
    contactPath,
    contactsPath,
    dashboardPath,
    employeePath,
    employeesPath,
    resourcePath,
    resourcesPath,
} from '../../routes/paths';
import { getIdsFromParams } from '../../modules/location/utils';
import EmployeeDialog from '../employeeDialog/EmployeeDialog';
import TaskDialog from '../tasks/dialog/TaskDialog';
import JournalDialog from '../journal/dialog/JournalDialog';
import AppointmentTypeDialog from '../appointmentType/AppointmentTypeDialog';
import AbsenceTypeDialog from '../absenceType/AbsenceTypeDialog';
import ContractDialog from '../contracts/dialog/ContractDialog';

export const CARD_DIALOG = 'CARD_DIALOG';
export const CONTACT_DIALOG = 'CONTACT_DIALOG';
export const RESOURCE_DIALOG = 'RESOURCE_DIALOG';
export const EMPLOYEE_DIALOG = 'EMPLOYEE_DIALOG';
export const TASK_DIALOG = 'TASK_DIALOG';
export const JOURNAL_DIALOG = 'JOURNAL_DIALOG';
export const APPOINTMENT_TYPE_DIALOG = 'APPOINTMENT_TYPE_DIALOG';
export const ABSENCE_TYPE_DIALOG = 'ABSENCE_TYPE_DIALOG';
export const CONTRACT_DIALOG = 'CONTRACT_DIALOG';

/**
 * Dialogs have the following config:
 *   component: node
 *      The Dialog Component .
 *
 *   viaLocation: bool
 *      Set to true, if the dialog should use the current location to open and close.
 *
 *   getProps: func
 *      Receives the current location parameters and should return the props that
 *      are passed to the dialog component. This method is called for all registered dialogs
 *      when the location changes. Therefore it should return something falsy if the dialog
 *      should not be opened with the given parameters.
 *
 *      Only used if `viaLocation = true`.
 *
 *   getLocation: func
 *      Receives the current dialog props and should return the corresponding location.
 *      The location should encode the dialog props in such a way, that `getProps` can extract
 *      them again later on. The location is then set as the new location.
 *      If the return value is something falsy, the dialog is opened directly without using the url.
 *
 *      Only used if `viaLocation = true`.
 *
 *   urlOnClose: func
 *       Receives the current url parameters and should return an url to redirect to
 *       after the dialog was closed.
 *
 *       Only used if `viaLocation = true`.
 */
export const DIALOG_MAPPING = {
    [CARD_DIALOG]: {
        component: CardModal,
        viaLocation: true,
        getProps: urlParams => {
            const { cardId, contactId } = getIdsFromParams(['cardId', 'contactId'], urlParams);
            return cardId && !contactId ? { cardId } : null;
        },
        getLocation: ({ cardId }) => cardId && cardPath(cardId),
        urlOnClose: () => dashboardPath,
    },
    [CONTACT_DIALOG]: {
        component: ContactDialog,
        viaLocation: true,
        getProps: urlParams => {
            const { cardId, contactId } = getIdsFromParams(['cardId', 'contactId'], urlParams);
            return contactId ? { contactId, contextCardId: cardId } : null;
        },
        getLocation: ({ contactId, contextCardId }) =>
            contactId && contactPath(contactId, contextCardId),
        urlOnClose: ({ cardId }) => (cardId ? cardPath(cardId) : contactsPath),
    },
    [EMPLOYEE_DIALOG]: {
        component: EmployeeDialog,
        viaLocation: true,
        getProps: urlParams => {
            const { employeeId } = getIdsFromParams(['employeeId'], urlParams);
            return employeeId
                ? {
                      employeeId,
                      ...('initTab' in urlParams ? { initTab: urlParams.initTab } : {}),
                  }
                : null;
        },
        getLocation: ({ employeeId, initTab }) => employeeId && employeePath(employeeId, initTab),
        urlOnClose: () => employeesPath,
    },
    [RESOURCE_DIALOG]: {
        component: ResourceDialog,
        viaLocation: true,
        getProps: urlParams => {
            const { resourceId } = getIdsFromParams(['resourceId'], urlParams);
            return resourceId
                ? {
                      resourceId,
                      ...('initTab' in urlParams ? { initTab: urlParams.initTab } : {}),
                  }
                : null;
        },
        getLocation: ({ resourceId, cloneResourceId, initTab }) =>
            resourceId && resourcePath(resourceId, { cloneResourceId, initTab }),
        urlOnClose: () => resourcesPath,
    },
    [TASK_DIALOG]: {
        component: TaskDialog,
        viaState: true,
    },
    [JOURNAL_DIALOG]: {
        component: JournalDialog,
        viaState: true,
    },
    [APPOINTMENT_TYPE_DIALOG]: {
        component: AppointmentTypeDialog,
        viaState: true,
    },
    [ABSENCE_TYPE_DIALOG]: {
        component: AbsenceTypeDialog,
        viaState: true,
    },
    [CONTRACT_DIALOG]: {
        component: ContractDialog,
        viaState: true,
    },
};
