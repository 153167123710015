import * as Yup from 'yup';

export const WORK_DURATION_DAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export const generateWorkDurationInitialValues = days =>
    days.reduce((carry, day) => {
        carry[`work_duration_${day}`] = 0;
        return carry;
    }, {});

export const getContractInitialValues = () => ({
    started_at: new Date(),
    ended_at: null,
    ...generateWorkDurationInitialValues(WORK_DURATION_DAYS),
    personnel_number: '',
    vacation_days: null,
    cancellation_period: null,
    costs: null,
    description: null,
});

export const getInitialValuesFromContract = contract =>
    contract
        ? {
              ...contract,
              ...(contract?.work_duration
                  ? contract.work_duration.reduce((carry, duration, index) => {
                        carry[`work_duration_${WORK_DURATION_DAYS[index]}`] = duration;
                        return carry;
                    }, {})
                  : {}),
          }
        : null;

const getDaysWorkDurationSchemas = () =>
    WORK_DURATION_DAYS.reduce((carry, day) => {
        carry[`work_duration_${day}`] = Yup.string();
        return carry;
    }, {});

export const contractSchema = Yup.object().shape({
    started_at: Yup.date().required('required'),
    ended_at: Yup.date()
        .nullable()
        .transform(date => (date instanceof Date && !isNaN(date) ? date : null)),
    ...getDaysWorkDurationSchemas(),
    /* work_duration_mon: Yup.string(),
    work_duration_tue: Yup.string(),
    work_duration_wed: Yup.string(),
    work_duration_thu: Yup.string(),
    work_duration_fri: Yup.string(),
    work_duration_sat: Yup.string(),
    work_duration_sun: Yup.string(), */
    personnel_number: Yup.string().nullable(),
    vacation_days: Yup.number()
        .nullable()
        /* .transform((_, value) => {
            if (String(value).includes('.')) {
                return null;
            }

            return +String(value).replace(/,/, '.');
        }) */
        .positive('positiveNumber'),
    cancellation_period: Yup.number().typeError('numberMismatch').nullable().positive(),
    costs: Yup.number()
        .nullable()
        /* .transform((_, value) => {
            if (String(value).includes('.')) {
                return null;
            }

            return +String(value).replace(/,/, '.');
        }) */
        .positive('positiveNumber'),
    description: Yup.string().nullable(),
});
